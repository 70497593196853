<template>
  <v-row class="flex-column" dense>
    <v-col v-if="showHeader" class="flex-shrink-1 flex-grow-0">
      <the-tools-bar>
        <template #tools-right>
          <global-filter
            :globalFilter="globalFilterValue"
            @search="searchGlobalFilter($event)"
            @updateGlobalFilterValue="e => (globalFilterValue = e)"
          />
          <handle-columns
            :crudName="crudParams.crudName"
            :headers="crudParams.headers"
            @updateHeadersSpecs="updateHeadersSpecs"
          />
          <import-items
            v-if="crudParams.canImport"
            :importText="importText"
            :crud-actions="crudActions"
          />
          <export-excel-btn
            v-if="crudParams.canExport"
            :options="options"
            :exportText="exportText"
            :export-excel-items-action="crudActions.exportExcelItems"
          />
          <create-item
            v-if="crudParams.canCreate"
            @itemCreated="loadItems"
            :create-text="createText"
            :crud-actions="crudActions"
          />
        </template>
      </the-tools-bar>
    </v-col>

    <slot name="tableHeader"></slot>

    <v-col v-if="filterableHeaders.length > 0 && showFilters" class="flex-shrink-1 flex-grow-0">
      <CrudFilterBar
        :filterableHeaders="filterableHeaders"
        :criteria="options.criteria"
        :global-filter="globalFilterValue"
        @updateCriteria="updateCriteria($event)"
        @resetOptions="resetOptions"
      />
    </v-col>

    <v-col id="tableCol" ref="tableCol" v-resize="calculateHeight" class="pa-0">
      <v-data-table
        v-model="itemsSelected"
        :headers="visibleHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :height="tableHeight"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, -1],
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Cantidad por página',
          'show-current-page': true,
        }"
        :hide-default-footer="itemsSelected.length > 1 && showFooter"
        multi-sort
        :show-select="enableSelection"
        fixed-header
        class="pa-0"
        @update:items-per-page="setUrlQuery($event, 'ipp')"
        @update:page="setUrlQuery($event, 'page')"
        @update:sort-by="setUrlQuery($event, 'sortBy')"
        @update:sort-desc="setUrlQuery($event, 'sortDesc')"
        @update:options="loadItemsWithOptions"
      >
        <!-- Clients -->
        <template #item.training_courses_count="{ item }">
          {{ item.training_courses_count }}
          <filter-btn
            route-name="trainingCourses"
            :criteria="[{ field: 'client', operator: 'e', value: item.name }]"
          />
        </template>

        <template #item.centers_count="{ item }">
          {{ item.centers_count }}
          <filter-btn
            route-name="centers"
            :criteria="[{ field: 'client', operator: 'e', value: item.name }]"
          />
        </template>

        <!-- Centers -->
        <template #item.centers_training_courses_count="{ item }">
          {{ item.centers_training_courses_count }}
          <filter-btn
            route-name="trainingCourses"
            :criteria="[{ field: 'client', operator: 'e', value: item.client }]"
          />
        </template>

        <!-- businessGroup -->
        <template #item.clients_count="{ item }">
          {{ item.clients_count }}
          <filter-btn
            route-name="clients"
            :criteria="[{ field: 'business_group', operator: 'e', value: item.name }]"
          />
        </template>

        <!-- Regenerate certificates -->
        <template #item.regenerateCertificates="{ item }">
          <regenerate-certificates-btn
            :element-id="item.id"
            :disabled="!item.actions.canRegenerate"
          />
        </template>

        <!-- Download documents -->
        <template #item.downloadDocuments="{ item }">
          <download-file-btn
            :endpoint-url="'documents/' + item.downloadDocuments + '/download'"
            small
            outlined
          />
        </template>

        <!-- Download Training Course Documents -->
        <template #item.downloadTrainingCourseDocuments="{ item }">
          <download-file-btn
            :endpoint-url="`documents/${item.downloadTrainingCourseDocuments}/download-for-training-course/${$route.params.id}`"
            small
            outlined
          />
        </template>

        <!-- Download Student Documents -->
        <template #item.downloadStudentDocuments="{ item }">
          <download-file-btn
            :endpoint-url="`documents/${item.downloadStudentDocuments}/download-for-student/${$route.params.id}`"
            small
            outlined
          />
        </template>

        <!-- Download files -->
        <template #item.downloadFiles="{ item }">
          <download-file-btn
            :endpoint-url="'files/' + item.downloadFiles + '/download'"
            small
            outlined
          />
        </template>

        <!-- Student edit -->
        <template #item.student_name_edit="{ item }">
          <router-link :to="{ name: 'studentsEdit', params: { id: item.student_id } }">
            {{ item.student_name_edit }}
          </router-link>
        </template>

        <template #item.actions="{ item }">
          <index-actions
            :item="item"
            @deleteItem="deleteItems($event)"
            :crud-actions="crudActions"
          />
        </template>

        <template #no-data>
          <div
            v-if="options.criteria.length || options.globalFilter || options.page !== 1"
            class="mt-4 mt-md-8"
          >
            No hay datos con estos filtros, prueba con otros o<br />
            <v-btn @click="resetOptions" class="mt-2" outlined> Elimina los filtros </v-btn>
          </div>
          <div v-else class="mt-4 mt-md-8">No hay datos para mostrar</div>
        </template>

        <template #footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
        </template>

        <template v-if="itemsSelected.length" #footer>
          <bulk-actions-panel
            :itemsSelected="itemsSelected"
            @deleteItems="deleteItems($event)"
            @refreshItems="loadItems"
            :crud-actions="crudActions"
          >
            <template #bulkActions="{ itemsSelected }">
              <slot name="bulkActions" :itemsSelected="itemsSelected"></slot>
            </template>
          </bulk-actions-panel>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import queryString from "query-string";

export default {
  components: {
    TheToolsBar: () => import("@/components/TheToolsBar.vue"),
    CrudFilterBar: () => import("@/components/crud-md/CrudMdFilterBar"),
    handleColumns: () => import("@/components/crud-md/CrudMdColumnsHandle"),
    GlobalFilter: () => import("@/components/crud-md/CrudMdGlobalFilter"),
    CreateItem: () => import("@/components/crud-md/actions/Create.vue"),
    IndexActions: () => import("@/components/crud-md/actions/index"),
    BulkActionsPanel: () => import("@/components/crud-md/CrudMdBulkActionsPanel"),
    ExportExcelBtn: () => import("@/components/crud-md/actions/ExportExcelBtn"),
    ImportItems: () => import("@/components/crud-md/actions/import-items"),
    filterBtn: () => import("@/components/crud-md/CrudMdFilterItemBtn"),
    downloadFileBtn: () => import("@/modules/cardioguard-admin/components/DownloadFileBtn"),
    regenerateCertificatesBtn: () =>
      import("@/modules/cardioguard-admin/components/RegenerateCertificatesBtn"),
  },
  props: {
    showHeader: { type: Boolean, default: true },
    showFilters: { type: Boolean, default: true },
    showFooter: { type: Boolean, default: true },
    enableSelection: { type: Boolean, default: false },
    listenCalculateHeight: { type: Boolean, default: null },
    listenRefreshItems: { type: Boolean, default: null },
    exportText: { type: String, default: "Exportar" },
    importText: { type: String, default: "Importar" },
    createText: { type: String, default: "Crear" },
    deleteConfirmText: {
      type: Object,
      default: () => {
        return {
          single: "¿Seguro que deseas eliminar este elemento?",
          multiple: "¿Seguro que deseas eliminar estos elementos?",
        };
      },
      validator(value) {
        const keys = Object.keys(value);
        return keys.length === 2 && keys.includes("single") && keys.includes("multiple");
      },
    },
    hiddenCriteria: { type: Array, default: () => [] },
    customActions: { type: Object, default: () => {} },
  },
  data() {
    return {
      itemsSelected: [],
      crudParams: {
        crudName: "",
        canCreate: false,
        canImport: null,
        canExport: null,
        headers: [],
      },
      items: [],
      totalItems: 0,
      loading: true,
      options: { criteria: [], globalFilter: null },
      tableHeight: 250,
      lastGetItems: null,
      globalFilterValue: null,
      firstUpdateOptions: true,
      currentService: null,
    };
  },
  computed: {
    filterableHeaders() {
      return this.crudParams.headers.filter(h => h.filterable);
    },
    visibleHeaders() {
      return this.crudParams.headers.filter(h => h.visible);
    },
    crudActions() {
      if (!this.currentService) return {};

      const defaultActionsNames = {
        getListHeaders: this.currentService.getListHeaders,
        getListItems: this.currentService.getListItems,
        deleteItems: this.currentService.deleteItems,
        getCreateItemForm: this.currentService.getCreateItemForm,
        createItem: this.currentService.createItem,
        saveColumns: this.currentService.saveColumns,
        exportExcelItems: this.currentService.exportExcelItems,
        getUploadingStatus: this.currentService.getUploadingStatus,
        downloadImportExcelTemplate: this.currentService.downloadImportExcelTemplate,
        getImportPath: this.currentService.getImportPath,
      };

      return { ...defaultActionsNames, ...this.customActions };
    },
  },
  beforeMount() {
    this.currentService = this.$store.state.currentService;
    this.loadHeaders();

    if (this.$route.query.page) {
      this.options.page = parseInt(this.$route.query.page);
    }
    if (this.$route.query.ipp) {
      this.options.itemsPerPage = parseInt(this.$route.query.ipp);
    }

    if (this.$route.query.criteria) {
      const parsedCriteria = queryString.parse(this.$route.query.criteria);
      const criteriaArray = [];
      for (let criteria in parsedCriteria) {
        criteriaArray.push(queryString.parse(parsedCriteria[criteria]));
      }

      this.options.criteria = criteriaArray;
    }

    if (this.$route.query.sortBy) {
      this.options.sortBy = this.$route.query.sortBy.split("-");
    }

    if (this.$route.query.sortDesc) {
      const booleanArr = [];
      for (let el of this.$route.query.sortDesc.split("-")) {
        booleanArr.push(el === "true");
      }
      this.options.sortDesc = booleanArr;
    }

    if (this.$route.query.globalFilter) {
      this.globalFilterValue = this.$route.query.globalFilter;
      this.options.globalFilter = this.$route.query.globalFilter;
    }
  },
  mounted() {
    this.loadItems();
    this.$nextTick(() => {
      this.calculateHeight();
    });

    if (this.listenRefreshItems) {
      this.$root.$on("refreshItems", () => this.loadItems());
    }
    if (this.listenCalculateHeight) {
      this.$root.$on("calculateCrudHeight", () => this.calculateHeight());
    }
  },
  methods: {
    calculateHeight() {
      this.tableHeight = window.innerHeight - this.$refs.tableCol.getBoundingClientRect().top - 80;
    },
    updateCriteria(newCriteria) {
      if (newCriteria.length) {
        const newQuery = { ...this.$route.query };
        newQuery.criteria = this.$encodeCriteria(newCriteria);

        this.$router.replace({ query: newQuery });
      } else if (this.$route.query.criteria) {
        let newQuery = Object.assign({}, this.$route.query);
        delete newQuery.criteria;

        this.$router.replace({ query: newQuery });
      }

      this.resetOptionsPage();
      this.options.criteria = newCriteria;
      this.loadItems();
    },
    resetOptionsPage() {
      this.options.page = 1;
    },
    resetOptions() {
      let emptyOptions = {};
      emptyOptions.page = 1;
      emptyOptions.criteria = [];
      emptyOptions.globalFilter = null;
      this.globalFilterValue = null;
      Object.assign(this.options, emptyOptions);
      this.loadItems();

      let newQuery = { ...this.$route.query };
      if (newQuery.page) delete newQuery.page;
      if (newQuery.criteria) delete newQuery.criteria;
      if (newQuery.globalFilter) delete newQuery.globalFilter;
      this.$router.replace({ query: newQuery });
    },
    setUrlQuery(param, queryName) {
      if (!queryName) return null;
      const queryObj = { ...this.$route.query };

      if (Array.isArray(param) && param.length) {
        queryObj[queryName] = param.join("-");
      } else if (param) {
        queryObj[queryName] = param;
      } else {
        delete queryObj[queryName];
      }

      this.$router.replace({ query: queryObj });
    },
    searchGlobalFilter() {
      this.options.globalFilter = this.globalFilterValue;
      this.resetOptionsPage();
      this.loadItems();
      this.setUrlQuery(this.globalFilterValue, "globalFilter");
    },
    async updateHeadersSpecs(headers, crudName) {
      this.crudParams.headers = headers;
      try {
        await this.crudActions.saveColumns(crudName, headers);
      } catch {
        this.$notifyError();
      }
    },
    async deleteItems(items) {
      if (!items) return null;
      const itemsIsArray = Array.isArray(items);
      let ids = null;
      itemsIsArray ? (ids = items.join(",")) : (ids = items);
      let confirmed = null;
      let message = itemsIsArray ? this.deleteConfirmText.multiple : this.deleteConfirmText.single;
      message += "<br/><br/>ID: " + ids;

      confirmed = await this.$confirm(message, {
        title: "Confirmación de eliminación",
      });

      if (confirmed) {
        try {
          const response = await this.crudActions.deleteItems(ids);
          const { errorMessage } = response.data;

          if (errorMessage) this.$notifyError(undefined, errorMessage);

          await this.loadItems();
          this.itemsSelected = [];
        } catch (e) {
          const message = "Error al eliminar el/los elemento/s | " + e;
          this.$notifyError(undefined, message);
        }
      }
    },
    async loadHeaders() {
      try {
        let response = await this.crudActions.getListHeaders();
        this.crudParams = response.data;
        if (this.crudParams.canImport) {
          this.$root.$on("refreshItems", () => this.loadItems());
        }
      } catch (e) {
        this.$notifyError();
      }
    },
    async loadItems() {
      this.loading = true;
      const now = new Date().getTime();

      if (now - this.lastGetItems > 50) {
        try {
          const options = { ...this.options };
          options.criteria = this.hiddenCriteria.concat(this.options.criteria);
          const response = await this.crudActions.getListItems(options);

          this.items = response.data.items;
          this.totalItems = response.data.metadata.totalRows;
        } catch (e) {
          this.$notifyError(undefined, "Error al cargar los datos de esta lista.");
        } finally {
          this.calculateHeight();
          this.lastGetItems = new Date().getTime();
          this.loading = false;
        }
      }
    },
    loadItemsWithOptions() {
      !this.firstUpdateOptions ? this.loadItems() : (this.firstUpdateOptions = null);
    },
  },
  beforeDestroy() {
    if (this.listenRefreshItems || this.crudParams.canImport) this.$root.$off("refreshItems");
    if (this.listenCalculateHeight) this.$root.$off("calculateCrudHeight");
  },
};
</script>

<style>
.prd-crud-edit-button {
  min-width: unset !important;
}
</style>
